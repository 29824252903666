
function getPath(vList, A, B) {
  let xEnd = A.length
  let yEnd = B.length
  let dEnd = vList.length - 1
  let path = [{
    x: xEnd,
    y: yEnd
  }]
  for (let i = 0 ; i < dEnd ; i++) {
    let d = dEnd - i
    let v = vList[d]
    let vPrev = vList[d - 1]
    let k = xEnd - yEnd

    let down = (k === -d || ( k !== d && v[k - 1] < v[k + 1]))
    let kPrev = down ? k + 1 : k - 1
    
    xEnd = vPrev[kPrev]
    yEnd = xEnd - kPrev
    path.push({
      x: xEnd,
      y: yEnd
    })
  }
  return path.reverse()
}
function diffLines(A, B) {
  // v[index]: 到达index位置时x的值
  // d: 步数 最大为 N+M
  // k: x-y
  let N = A.length, M = B.length
  let vList = []
  let v = {}
  v[1] = 0
  for (let d = 0 ; d <= N + M ; d++) {
    let flag = false
    for (let k = -d ; k <= d ; k += 2) {
      let down = (k === -d || ( k !== d && v[k - 1] < v[k + 1]))
      let kPrev = down ? k + 1 : k - 1

      let xStart = v[kPrev]
      let yStart = xStart - kPrev

      let xEnd = down ? xStart : xStart + 1
      let yEnd = xEnd - k

      while (xEnd <= N && yEnd <= M && A[xEnd] && B[yEnd] && A[xEnd]=== B[yEnd]) {
        xEnd++
        yEnd++
      }

      v[k] = xEnd
      if (xEnd >= N && yEnd >= M ) {
        flag = true
        break
      }
    }
    vList.push(JSON.parse(JSON.stringify(v)))
    if (flag) break
  }
  return getPath(vList, A, B)
}

export default {
  diffLines
}