<template>
  <div class="check-share-file-container">
    <div class="check-share-file-header">
      <div class="check-share-file-header-title">
        Check Grant Segment
      </div>
      <div class="check-share-file-header-icon">
        <Tooltip placement="right" max-width="300">
          <div slot="content">You can preview all files shared to you. Any file that has a conflict will be marked red.</div>
          <Icon type="md-help-circle" size="20" />
        </Tooltip>
      </div>
      <div class="check-share-file-header-button">
        <Button :disabled="state!=='untreated'" :type="conflict ? 'warning' : 'success' " @click="confirm"><Icon :type="conflict ? 'md-information-circle' : 'md-checkmark-circle'" /> Pass </Button>
      </div>
      <div class="check-share-file-header-button">
        <Button :disabled="state!=='untreated'" type="error" @click="reject"><Icon type="md-close-circle" /> Reject </Button>
      </div>
    </div>
    <div class="check-share-file-tree">
      <myTree :treeData="treeData" @onNodeClick="onNodeClick"></myTree>
    </div>
    <div class="check-share-file-content-box">
      <div class="check-share-file-content" v-if="contentType === 'empty'">
        <div class="content-line">
          Select a file to see its content!
        </div>
      </div>
      <div class="check-share-file-content" v-else-if="contentType === 'loading'">
        <div class="content-line">
          Please wait for a few seconds...
        </div>
      </div>
      <div class="check-share-file-content" v-else-if="contentType === 'error'">
        <div class="content-line">
          Failed to load file data.
        </div>
      </div>
      <div class="check-share-file-content" v-else-if="contentType === 'content'">
        <div class="content-line" 
          v-for="(line, index) in fileContent" 
          :key="index"
        >
          <span class="content-line-num">{{index + 1}}</span>
          {{line}}
        </div>
      </div>
      <div class="check-share-file-content" v-else-if="contentType === 'conflict'">
        <div :class="{'content-line':true, 'content-line--add': line.type==='add', 'content-line--delete': line.type==='delete'}" 
          v-for="(line, index) in diffedContent" 
          :key="index"
        >
          <span class="content-line-num">{{ line.type!=='add' ? line.prevLineNum : ''}}</span>
          <span class="content-line-num">{{ line.type!=='delete' ? line.currentLineNum : ''}}</span>
          <Icon size="6" type="md-add" v-if="line.type==='add'"/>
          <Icon size="6" type="md-remove" v-if="line.type==='delete'"/>
          <Icon size="6" type="md-remove" style="visibility: hidden" v-if="line.type==='retain'"></Icon>
          {{line.content}} 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Diff from '@/utils/diff.js'
import myTree from '../components/myTree.vue';

export default {
  components: {
    myTree
  },
  data() {
    return {
      grantSegmentId: '',
      treeData: [],
      conflict: false,
      fileContent: [],
      state: 'pass',
      conflictFileContent: [],
      diffedContent: [],
      contentType: 'empty',
    }
  },
  methods: {
    getGrantSegment() {
      this.$store.dispatch('getGrantSegment', this.grantSegmentId).then(res => {
        let fileList = res.data.data.items.fileInformation
        this.state = res.data.data.items.state
        this.treeData = this.setFileTree(fileList)
      })
    },
    setFileTree(fileList) {
      /** 小于此值的与文件路径无关 */
      let COMMON_LEVEL = 8
      let Tself = this
      fileList.forEach(file => {
        file.paths = file.absolutePath.split('/').slice(COMMON_LEVEL)
        if (file.conflict) Tself.conflict = true
        if (file.isDir) {
          file.children = []
          file.expand = false
        }
      })
      let res = []
      // 确定父子关系
      for (let file of fileList) {
        if (file.paths.length === 1) {
          res.push(file)
          continue
        }
        let hasFoundParent = false
        fileList.forEach(tmp => {
          if (tmp === file) return
          if (tmp.paths.join() === file.paths.slice(0,file.paths.length - 1).join()) {
            hasFoundParent = true
            tmp.children.push(file)
          }
        })
        if (!hasFoundParent) {
          let newFile = {
            absolutePath: file.paths.slice(0,file.paths.length - 1).join('/'),
            paths: file.paths.slice(0,file.paths.length - 1),
            conflict: file.conflict,
            isDir: true,
            children: [file],
            expand: false
          }
          fileList.push(newFile)
        }
      }
      fileList.forEach(file => {
        file.title = file.paths[file.paths.length - 1]
        delete file.paths
      })
      return res
    },
    onNodeClick(nodeData) {
      if (nodeData.isDir) {
        this.contentType = 'empty'
        return
      }
      this.contentType = 'loading'
      this.fileContent = []
      this.conflictFileContent = []
      if (!nodeData.conflict) {
        this.getFileContent(nodeData).then(res => {
          console.log(res)
          this.fileContent = res.data.data.items
          this.contentType = 'content'
        }).catch(err => {
          this.contentType = 'error'
        })
        return
      }
      Promise.all([this.getFileContent(nodeData), this.getConflictFileContent(nodeData)]).then(values => {
        this.fileContent = values[0].data.data.items
        this.conflictFileContent = values[1].data.data.items
        this.diffedContent = this.getDiffedLines(this.conflictFileContent, this.fileContent)
        console.log(this.diffedContent)
        this.contentType = 'conflict'
      }).catch(err => {
        console.log(err)
        this.contentType = 'error'
      })
    },
    confirm() {
      if (!this.conflict) {
        this.agreeGrantSegment()
        return
      }
      this.$Modal.confirm({
        content: `<p>Are you sure to agree these files? All conflicting files will be overwritten!</p>`,
        okText: 'OK',
        cancelText: 'Cancel',
        onOk: this.agreeGrantSegment
      })
    },
    reject() {
      this.$Modal.confirm({
        content: `<p>Are you sure to reject this file sharing?</p>`,
        okText: 'Yes',
        cancelText: 'Cancel',
        onOk: this.rejectGrantSegment
      })
    },
    agreeGrantSegment() {
      this.$store.dispatch('agreeGrantSegment', this.grantSegmentId).then(res => {
        let data = res.data.data.items
        if (data === 'success') {
          this.$Message.success('Successfully passed the files.')
          this.state = 'pass'
        } else {
          this.$Message.error(data)
        }
      })
    },
    rejectGrantSegment() {
      this.$store.dispatch('rejectGrantSegment', this.grantSegmentId).then(res => {
        let data = res.data.data.items
        if (data === 'success') {
          this.$Message.success('Successfully rejected the files.')
          this.state = 'reject'
        } else {
          this.$Message.error(data)
        }
      })
    },
    getFileContent(file) {
      if (file.isDir) return
      return this.$store.dispatch('getFileContent',{
        path: file.absolutePath,
        selectedLine: file.selectedLine  
      })
    },
    getConflictFileContent(file) {
      if (file.isDir) return
      return this.$store.dispatch('getFileContent', {
        path: file.conflictFilePath,
        selectedLine: []
      })
    },
    getDiffedLines(diffFrom, diffTo) {
      let diffRes = Diff.diffLines(diffFrom, diffTo)
      let lines = []
      let currentLineNum = 1
      let prevLineNum = 1
      for (let i = 0 ; i + 1 < diffRes.length ; i++) {
        let prev = diffRes[i]
        let next = diffRes[i + 1]
        let diffX = next.x - prev.x
        let diffY = next.y - prev.y
        let down = diffX < diffY
        if (down) {
          lines.push({
            content: diffTo[prev.y],
            type: 'add',
            prevLineNum,
            currentLineNum
          })
          currentLineNum++
          while(diffX > 0) {
            lines.push({
              content: diffTo[next.y - diffX],
              type: 'retain',
              prevLineNum,
              currentLineNum
            })
            currentLineNum++
            prevLineNum++
            diffX--
          }
        } else {
          lines.push({
            content: diffFrom[prev.x],
            type: 'delete',
            prevLineNum,
            currentLineNum
          })
          prevLineNum++
          while(diffY > 0) {
            lines.push({
              content: diffFrom[next.x - diffY],
              type: 'retain',
              prevLineNum,
              currentLineNum
            })
            currentLineNum++
            prevLineNum++
            diffY--
          }
        }
      }
      return lines
    }
  },
  mounted() {
    this.grantSegmentId = this.$route.query.id
    this.getGrantSegment()
  }
}
</script>

<style lang="less">
.check-share-file-container {
  overflow: auto;
  text-align: left;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  max-height: calc(~"100vh - 60px");
  .check-share-file-header {
    display: flex;
    align-items: center;
    height: 60px;
    &-title {
      font-size: 24px;
      margin-right: 10px;
      font-weight: 700;
    }
    &-icon {
      flex: 1 0 0;
      margin-right: 10px;
    }
    &-button {
      flex: 0 0 auto;
      margin-left: 10px;
    }
  }
  .check-share-file-tree {
    max-height: calc(~"100vh - 120px");
    width: 30%;
    overflow: auto;
    float: left;
  }
  .check-share-file-content-box {
    height: calc(~"100vh - 140px");
    width: 70%;
    overflow: auto;
    float: left;
    border: 1px solid #666;
    .check-share-file-content {
      min-width: 100%;
      float:left;
      .content-line {
        white-space: pre;
        &--add {
          background-color: #ecfdf0;
        }
        &--delete {
          background-color: #fbe9eb;
        }
      }
      .content-line-num {
        display: inline-block;
        width: 30px;
        text-align: right;
        margin-right: 10px;
      }
    }
  }
}

</style>