<template>
  <div class="tree-container">
    <treeNode v-for="node in treeData" :key="node.id" :nodeData="node" @onNodeClick="onNodeClick"></treeNode>
  </div>
</template>

<script>
import treeNode from './treeNode.vue';
export default {
  components: {
    treeNode
  },
  props: {
    'treeData': Array
  },
  methods: {
    onNodeClick(nodeData) {
      this.$emit('onNodeClick', nodeData)
    }
  }

}
</script>

<style>
.tree-container {
  padding: 5px;
}
</style>