<template>
  <div class="tree-node">
    <span class="tree-node-icon" @click="onExpandClick">
      <Icon type="ios-arrow-forward" v-if="nodeData.children && nodeData.children.length && !nodeData.expand"/>
      <Icon type="ios-arrow-down" v-else-if="nodeData.children && nodeData.children.length && nodeData.expand"/>
      <Icon type="ios-arrow-down hidden" v-else/>
    </span>
    <span @click="treeNodeClick">
      <span class="tree-node-title" v-if="!nodeData.conflict">{{ nodeData.title }}</span>
      <span class="tree-node-title--conflict" v-else>{{ nodeData.title }}</span>
    </span>
    <div class="tree-node-children" v-show="nodeData.expand">
      <treeNode v-for="childNode in nodeData.children" :key="childNode.id" :nodeData="childNode" @onNodeClick="onNodeClick"></treeNode>
    </div>
  </div>
</template>

<script>
export default {
  name: 'treeNode',
  props: {
    'nodeData': Object
  },
  methods: {
    onExpandClick () {
      if (this.nodeData.isDir && this.nodeData.children.length) {
        this.nodeData.expand = !this.nodeData.expand
      }
    },
    treeNodeClick () {
      this.$emit('onNodeClick', this.nodeData)
    },
    onNodeClick (nodeData) {
      this.$emit('onNodeClick', nodeData)
    }
  },

}
</script>

<style lang="less">
.tree-node {
  white-space: nowrap;
  .tree-node-icon {
    cursor: pointer;
    .hidden {
      opacity: 0;
    }
  }
  .tree-node-title {
    cursor: pointer;
    display: inline-block;
    margin-left: 5px;
    margin-bottom: 5px;
    white-space: nowrap;
    &--conflict {
      cursor: pointer;
      display: inline-block;
      margin-left: 5px;
      margin-bottom: 5px;
      white-space: nowrap;
      color: red;
    }
  }
  .tree-node-children {
    margin-left: 15px;
  }
}
</style>